exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-map-test-jsx": () => import("./../../../src/pages/map-test.jsx" /* webpackChunkName: "component---src-pages-map-test-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-basic-report-jsx": () => import("./../../../src/templates/BasicReport.jsx" /* webpackChunkName: "component---src-templates-basic-report-jsx" */),
  "component---src-templates-extended-report-jsx": () => import("./../../../src/templates/ExtendedReport.jsx" /* webpackChunkName: "component---src-templates-extended-report-jsx" */),
  "component---src-templates-flexible-resource-jsx": () => import("./../../../src/templates/FlexibleResource.jsx" /* webpackChunkName: "component---src-templates-flexible-resource-jsx" */),
  "component---src-templates-focus-area-jsx": () => import("./../../../src/templates/FocusArea.jsx" /* webpackChunkName: "component---src-templates-focus-area-jsx" */),
  "component---src-templates-job-jsx": () => import("./../../../src/templates/Job.jsx" /* webpackChunkName: "component---src-templates-job-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-person-page-jsx": () => import("./../../../src/templates/PersonPage.jsx" /* webpackChunkName: "component---src-templates-person-page-jsx" */),
  "component---src-templates-policy-toolkit-jsx": () => import("./../../../src/templates/PolicyToolkit.jsx" /* webpackChunkName: "component---src-templates-policy-toolkit-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-strategic-imperative-jsx": () => import("./../../../src/templates/StrategicImperative.jsx" /* webpackChunkName: "component---src-templates-strategic-imperative-jsx" */),
  "component---src-templates-update-jsx": () => import("./../../../src/templates/Update.jsx" /* webpackChunkName: "component---src-templates-update-jsx" */)
}

